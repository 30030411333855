import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    borderRadius: spacing(2),
  },
}))

function PaperStyled({ children, className, ...props }) {
  const classes = useStyles()

  return (
    <Paper {...props} className={clsx(classes.root, className)}>
      {children}
    </Paper>
  )
}

export default PaperStyled
