import React, { useContext, useEffect } from 'react'
import { useStateWithMerge } from 'hooks'
import { TOKEN_KEY, USER_KEY } from 'config/constants'
const AuthContext = React.createContext()
const getInitialState = () => ({
  user: null,
  isLoading: false,
})

function AuthProvider({ children }) {
  const [state, setState] = useStateWithMerge(getInitialState())

  useEffect(() => {
    const userPrev = JSON.parse(localStorage.getItem(USER_KEY))
    if (userPrev) {
      setState({ user: userPrev })
    }
  }, [])

  function logIn({ token, user }) {
    localStorage.setItem(TOKEN_KEY, `Bearer ${token}`)
    localStorage.setItem(USER_KEY, JSON.stringify(user))
    return setState({ user })
  }

  function logOut() {
    cleanStorage(false)
    setTimeout(() => {
      return setState({ user: null })
    }, 500)
  }
  // eslint-disable-next-line consistent-return
  function cleanStorage(clearState = true) {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USER_KEY)
    if (clearState) {
      return setState({ user: null })
    }
  }
  const { user, isLoading } = state
  function updateProfile(data) {
    return setState({ ...state, user: { ...user, ...data } })
  }
  return (
    <AuthContext.Provider value={{ user, logIn, logOut, updateProfile, isLoading, cleanStorage }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthProvider, useAuth }
