import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

function DialogStyled({
  children,
  title = null,
  onClose = () => {},
  open = false,
  fullWidth = true,
  maxWidth = `md`,
  closeActionText = `Cerrar`,
  acceptActionText = `Aceptar`,
  acceptAction = null,
  withOutPadding = false,
  isLoading = false,
  ...props
}) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="dialog-styled"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      {title && <DialogTitle id="dialog-styled">{title}</DialogTitle>}
      <DialogContent style={withOutPadding ? { padding: 0 } : {}}>{children}</DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {closeActionText}
        </Button>
        {acceptAction && (
          <Button onClick={acceptAction} autoFocus>
            {isLoading ? <CircularProgress color="primary" size={20} /> : acceptActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DialogStyled
