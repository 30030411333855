import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider, FeedbackProvider, useAuth } from './context'
import PrivateApp from './PrivateApp'
import PublicApp from './PublicApp'

function App() {
  const { user } = useAuth()

  return (
    <ThemeProvider>
      <FeedbackProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={PublicApp} />
            <Route path="/home" component={PrivateApp} />
            {user && <Route path="/home" component={PrivateApp} />}
            <Route path="/" component={PublicApp} />
          </Switch>
        </Router>
      </FeedbackProvider>
    </ThemeProvider>
  )
}

export default App
