import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(({ spacing, shadows, palette }) => ({
  root: {
    borderRadius: spacing(8),
    boxShadow: shadows[1],
    backgroundColor: `#FFF`,
    '& > .MuiFormLabel-root': {
      paddingLeft: spacing(3),
      color: palette.secondary.main,
      '&.MuiInputLabel-shrink': {
        paddingTop: spacing(1),
        paddingLeft: `32px`,
      },
    },
    '& > .MuiInputBase-root': {
      padding: spacing(0, 3),
      paddingBottom: `13px`,
      paddingTop: `5px`,
    },
  },
  multilineRoot: {
    borderRadius: spacing(2),
    boxShadow: shadows[1],
    backgroundColor: `#FFF`,
    '& > .MuiFormLabel-root': {
      paddingLeft: spacing(3),
      color: palette.secondary.main,
      '&.Mui-focused': {
        marginTop: spacing(1),
      },
      '&.MuiFormLabel-filled': {
        marginTop: spacing(1),
      },
    },
    '& > .MuiInputBase-root': {
      padding: spacing(1, 3),
    },
  },
  errorComponent: {
    paddingLeft: spacing(3),
  },
}))

function TextFieldInput({
  type = `text`,
  label = ``,
  multiline = false,
  onChange = () => {},
  InputProps,
  errorMsg = null,
  ...props
}) {
  const classes = useStyles()

  return (
    <>
      <TextField
        {...props}
        onChange={({ target }) => onChange(target.value)}
        classes={{ root: multiline ? classes.multilineRoot : classes.root }}
        type={type}
        fullWidth
        label={label}
        multiline={multiline}
        InputProps={{ ...InputProps, disableUnderline: true }}
      />
      {errorMsg && (
        <FormControl error>
          <FormHelperText id="component-error-text" className={classes.errorComponent}>
            {errorMsg}
          </FormHelperText>
        </FormControl>
      )}
    </>
  )
}

export default TextFieldInput
