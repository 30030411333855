import React, { useContext, useEffect } from 'react'
import { useStateWithMerge } from 'hooks'
import { useAuth } from 'context'

import API from 'config/api'

export const endPointEnum = {
  getTasks: 0,
  getTrees: 1,
  getOrderTasks: 3,
  getPhytosanitaryStatesByUnitId: 5,
  getLocationsByUnitId: 6,
  getSubUnitsByUnitId: 7,
}

const TaskContext = React.createContext()
const getInitialState = () => ({
  task: null,
  taskId: null,
  isLoading: false,
  enableGoBack: false,
  tasks: [],
  species: [],
  taskPriorities: [],
  phytosanitaryStates: [],
  locations: [],
  subUnits: [],
  taskTypes: [],
  updateTaskView: false,
  showTaskView: false,
})

function TaskProvider({ children }) {
  const { user } = useAuth()
  const [state, setState] = useStateWithMerge(getInitialState())

  useEffect(() => {
    if (user) getData()
  }, [user])

  async function getData() {
    const { unitId } = user ?? {}
    try {
      const promises = []
      promises[endPointEnum.getTasks] = API.getAllTask()
      promises[endPointEnum.getTrees] = API.getTrees()
      promises[endPointEnum.getOrderTasks] = API.getOrderTasks()
      promises[endPointEnum.getPhytosanitaryStatesByUnitId] = API.getPhytosanitaryStatesByUnitId({ unitId })
      promises[endPointEnum.getLocationsByUnitId] = API.getLocationsByUnitId({ unitId })
      promises[endPointEnum.getSubUnitsByUnitId] = API.getSubUnitsByUnitId(unitId)

      const data = await Promise.all(promises)
      setData(data)
    } catch (message) {
      console.log(message)
    }
  }

  function setData(data) {
    const species = data[endPointEnum.getTrees].map((tree) => ({
      ...tree,
      description: `${tree.description}, (${tree.specie})`,
    }))

    setState({
      tasks: data[endPointEnum.getTasks] ?? [],
      species: species ?? [],
      taskTypes: data[endPointEnum.getOrderTasks] ?? [],
      phytosanitaryStates: data[endPointEnum.getPhytosanitaryStatesByUnitId] ?? [],
      locations: data[endPointEnum.getLocationsByUnitId] ?? [],
      subUnits: data[endPointEnum.getSubUnitsByUnitId] ?? [],
    })
  }

  async function getTask(taskId) {
    setState({ taskId, isLoading: true })
    const task = await API.getTaskById({ taskId })
    setState({ task, enableGoBack: true, isLoading: false, showTaskView: true })
  }

  function resetView() {
    setState({ task: null, enableGoBack: false, taskId: null, updateTaskView: false, showTaskView: false })
  }

  async function updatedTask(taskId) {
    setState({ taskId, isLoading: true })
    const task = await API.getTaskById({ taskId })
    setState({
      task,
      updateTaskView: true,
      enableGoBack: true,
      isLoading: false,
      taskId,
      showTaskView: false,
    })
  }

  function getTaskCreationSelectorValues() {
    const { species, taskPriorities, phytosanitaryStates, locations, subUnits, taskTypes } = state
    return {
      species,
      taskPriorities,
      phytosanitaryStates,
      locations,
      subUnits,
      taskTypes,
    }
  }

  async function handleGetAllTaskByTypeId() {
    setState({ isLoading: true })
    const allTask = await API.getAllTaskByTypeId()
    setState({ tasks: allTask, isLoading: false })
  }

  async function handleGetAllTask() {
    setState({ isLoading: true })
    const allTask = await API.getAllTask()
    setState({ tasks: allTask, isLoading: false })
  }

  return (
    <TaskContext.Provider
      value={{
        ...state,
        getTask,
        resetView,
        updatedTask,
        getTaskCreationSelectorValues,
        handleGetAllTaskByTypeId,
        handleGetAllTask,
      }}
    >
      {children}
    </TaskContext.Provider>
  )
}

function useTask() {
  const context = useContext(TaskContext)
  if (!context) {
    throw new Error(`useTask must be used within a TaskProvider`)
  }
  return context
}

export { TaskProvider, useTask }
