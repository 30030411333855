import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Box, BoxHeader, ListTasks } from 'components'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
    padding: spacing(2, 3),
  },
  rootSecondary: {
    paddingTop: spacing(1),
    backgroundColor: `#FFF`,
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    overflow: `auto`,
  },
  btnNormalize: {
    textTransform: `none`,
  },
}))

function Task({ getTask, allTasks, updatedTask, orderByTypeId = () => {}, orderByStatus = () => {} }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOrderByTypeId = () => {
    orderByTypeId()
    handleClose()
  }
  const handleOrderByStatus = () => {
    orderByStatus()
    handleClose()
  }

  return (
    <>
      <BoxHeader className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Listado de tareas</Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.btnNormalize}
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
            >
              Ordenar por
            </Button>
            <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleOrderByTypeId}>Tipo de tarea</MenuItem>
              <MenuItem onClick={handleOrderByStatus}>Estado de la OT</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </BoxHeader>
      <Box className={classes.rootSecondary}>
        {allTasks.length > 0 ? (
          <ListTasks tasks={allTasks} showAll={true} getTask={getTask} actionChip={updatedTask} />
        ) : (
          <Typography variant="h6" align="center">
            Usted no posee OT asignadas o no se encontraron OT creadas
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Task
