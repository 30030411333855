import React, { useEffect, useRef, useCallback } from 'react'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { useDebouncedCallback } from 'use-debounce'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useStateWithMerge } from 'hooks'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  BoxHeader,
  TextFieldInput,
  Button,
  SelectorInput,
  AddressInput,
  UploadImage,
  DialogApproveTask,
  AutocompleteInput,
} from 'components'
import API from 'config/api'
import { useTask, useFeedback } from 'context'
import { treeAgesEnum, generalStateEnum, orderTaskPlantationEnum } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
    padding: spacing(2, 3),
  },
  rootSecondary: {
    paddingTop: 0,
    overflow: `auto`,
    overflowX: `hidden`,
  },
  inputContainer: {
    paddingTop: spacing(2),
  },
  btnNormalize: {
    textTransform: `none`,
  },
  loaderContainer: {
    marginTop: spacing(5),
  },
}))

function NewTask({ history }) {
  const classes = useStyles()
  const setFeedback = useFeedback()
  const { handleGetAllTask, getTaskCreationSelectorValues, resetView } = useTask()
  const selectorsValues = getTaskCreationSelectorValues()
  const { phytosanitaryStates, locations, subUnits, species, taskTypes } = selectorsValues
  const selectorDefaultValue = `select`

  const [state, setState] = useStateWithMerge({
    isLoading: false,
    formIsValid: false,
    streetData: [],
    textAddress: ``,
    addressErrorCanBeShown: false,
    openDialog: false,
    files: [],
    screenShot: false,
    openConfirmationDialog: false,
    taskDialog: {},
    addressData: {},
    trees: [],
  })
  const {
    isLoading,
    formIsValid,
    streetData,
    textAddress,
    addressErrorCanBeShown,
    openDialog,
    files,
    screenShot,
    openConfirmationDialog,
    taskDialog,
    addressData,
    trees,
  } = state

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      subUnitId: selectorDefaultValue,
      treeId: selectorDefaultValue,
      orderTaskId: selectorDefaultValue,
      generalState: selectorDefaultValue,
      phytosanitaryStateId: selectorDefaultValue,
      locationId: selectorDefaultValue,
      treeAge: selectorDefaultValue,
    },
  })
  const {
    code,
    subUnitId,
    treeId,
    orderTaskId,
    generalState,
    phytosanitaryStateId,
    locationId,
    treeDap,
    timeLimit,
    treeAge,
    treeHeight,
    qtyTree,
    observation,
  } = watch()
  const maxFileImg = 2097152
  const imgAccepted = `image/jpg, image/jpeg, image/png`
  const webcamRef = useRef(null)

  const checkTextLength = (text, minLength) => text && text.trim().length >= minLength
  const checkSelectorValue = (value) => value !== selectorDefaultValue
  const validateForm = () => {
    //required text inputs
    const codeIsValid = checkTextLength(code, 1)
    const timeLimitIsValid = checkTextLength(timeLimit, 1)
    const addressIsValid = checkTextLength(textAddress, 1)
    const treeDapIsValid = checkTextLength(treeDap, 1)
    //required selectors
    const subUnitIsValid = checkSelectorValue(subUnitId)
    const treeSpecieValid = checkSelectorValue(treeId)
    const orderTaskIsValid = checkSelectorValue(orderTaskId)
    const generalStateIsValid = checkSelectorValue(generalState)
    const phytosanitaryStateIsValid = checkSelectorValue(phytosanitaryStateId)
    const locationIsValid = checkSelectorValue(locationId)
    const treeAgenIsValid = checkSelectorValue(treeAge)

    setState({
      formIsValid:
        codeIsValid &&
        timeLimitIsValid &&
        addressIsValid &&
        treeDapIsValid &&
        subUnitIsValid &&
        treeSpecieValid &&
        orderTaskIsValid &&
        generalStateIsValid &&
        phytosanitaryStateIsValid &&
        locationIsValid &&
        treeAgenIsValid,
    })
  }

  useEffect(() => {
    validateForm()
  }, [
    code,
    textAddress,
    treeDap,
    subUnitId,
    treeId,
    orderTaskId,
    generalState,
    phytosanitaryStateId,
    locationId,
    treeAge,
  ])

  useEffect(() => {
    if (orderTaskId && orderTaskId !== selectorDefaultValue) {
      if (orderTaskId === orderTaskPlantationEnum) {
        const filterSpecies = species.filter(({ unitPrice }) => parseFloat(unitPrice) > 0)
        setState({ trees: filterSpecies })
      } else {
        setState({ trees: species })
      }
    }
  }, [orderTaskId])

  const onSearchAddressChange = (value) => {
    const currentValue = value
    if (currentValue.length < 2) {
      setState({ streetData: [] })
    }
    if (currentValue.length > 5) {
      const autocomplete = new window.google.maps.places.AutocompleteService()
      autocomplete.getPlacePredictions(
        {
          input: currentValue,
          componentRestrictions: { country: `cl` },
        },
        (predictions) => {
          setState({ streetData: predictions })
        },
      )
    }
  }

  const debounceSearch = useDebouncedCallback((value) => onSearchAddressChange(value), 3000)

  const onSelect = async (street) => {
    if (street) {
      const { description: addressDescription, structured_formatting: data } = street
      setState({ textAddress: addressDescription })
      const geoCode = await getGeocode({ address: addressDescription })
      const { lat, lng } = await getLatLng(geoCode[0])

      const info = {
        latitude: lat,
        longitude: lng,
        streetName: data.main_text,
        streetNumber: `0`,
      }

      const streetNumber = data.main_text.match(/\d+/g)
      if (streetNumber) {
        info.streetName = data.main_text.replace(` ${streetNumber[0]}`, ``)
        info.streetNumber = streetNumber[0]
      }
      setState({ addressData: info })
    } else {
      //When the selected option is removed from the input, street is null
      //And in that case, states related to the address must be set to default
      setState({
        streetData: [],
        textAddress: ``,
        addressData: {},
      })
    }
  }

  const handleSearch = (value) => {
    setState({ textAddress: value })
    debounceSearch.callback(value)
  }

  const onErrors = () => {
    setState({ addressErrorCanBeShown: true })
  }

  const handleOpenDialog = () => {
    if (!isLoading) {
      setState({ openDialog: true })
    }
  }

  const handleCloseDialog = () => {
    setState({ openDialog: false, screenShot: false })
  }

  const handleOnSelected = ({ file, name, originalFile }) => {
    const currentDate = Math.round(new Date().getTime() / 1000)

    setState((prevState) => {
      const { files } = prevState
      const nextFiles = files.length ? [...files] : []
      nextFiles.push({ file, name: `${currentDate}_${name}`, originalFile })
      return { files: nextFiles }
    })
  }

  const handleOnDeleted = (index) => {
    const nextFiles = files.filter((file, key) => index !== key)
    setState({ files: nextFiles })
  }

  const capture = useCallback(() => {
    const file = webcamRef.current.getScreenshot()
    fetch(file)
      .then((res) => res.blob())
      .then((blob) => {
        handleOnSelected({ file, name: `screenshot.jpeg`, originalFile: blob })
      })
    handleCloseDialog()
  }, [webcamRef])

  const handleScreenShot = () => {
    setState({ screenShot: true })
  }

  const handleUpload = async (idTask) => {
    for await (const data of files) {
      const { originalFile, name } = data
      await uploadFile({ file: originalFile, name, idTask })
    }
  }

  async function uploadFile({ file, name, idTask }) {
    const formData = new FormData()
    formData.append(`file`, file)
    formData.append(`name`, name)
    formData.append(`idTask`, idTask)
    await API.uploadFile(
      { formData },
      {
        headers: { 'Content-Type': `multipart/form-data` },
      },
    )
  }

  const onSubmit = async () => {
    try {
      setState({ isLoading: true })
      const dataToSubmit = {
        code,
        treeDap,
        subUnitId,
        treeId: treeId?.id,
        orderTaskId,
        generalState,
        phytosanitaryStateId,
        locationId,
        address: textAddress,
        treeAge,
        timeLimit: Number(timeLimit),
        ...addressData,
      }

      if (treeHeight && checkTextLength(treeHeight, 1)) {
        dataToSubmit.treeHeight = treeHeight
      }

      if (qtyTree && checkTextLength(qtyTree, 1)) {
        dataToSubmit.qtyTree = qtyTree
      }

      if (observation && checkTextLength(observation, 1)) {
        dataToSubmit.observation = observation
      }

      const result = await API.createTaskRequest(dataToSubmit)

      const { id } = result
      if (files.length) {
        await handleUpload(id)
      }

      await handleGetAllTask()

      setFeedback({
        message: `Se ha creado la OT satisfactoriamente`,
        type: `success`,
        open: true,
      })
      resetView()
      history.push(`/home/task`)
    } catch (error) {
      setState({ isLoading: false })
      setFeedback({
        message: error,
        type: `error`,
        open: true,
      })
    }
  }

  const handleMaxSizeFile = (error) => {
    if (error) {
      setFeedback({
        message: `El archivo no puede pesar más de ${maxFileImg / 1024 / 1024} MB`,
        type: `error`,
        open: true,
      })
    }
  }

  const handleOpenConfirmationDialog = () => {
    const { description: title } = taskTypes.filter((task) => task.id === orderTaskId)[0] ?? {}
    setState({ openConfirmationDialog: true, taskDialog: { code, title } })
  }

  const handleCloseConfirmationDialog = () => {
    setState({ openConfirmationDialog: false, taskDialog: {} })
  }

  return (
    <>
      <BoxHeader className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Ingresa los datos de la OT</Typography>
          </Grid>
        </Grid>
      </BoxHeader>
      <Box className={classes.rootSecondary}>
        <form
          onSubmit={handleSubmit(handleOpenConfirmationDialog, onErrors)}
          noValidate
          autoComplete="off"
          style={{ width: `100%` }}
        >
          <Grid container justify="center">
            <Grid item xs={12} className={classes.inputContainer}>
              <TextFieldInput
                disabled={isLoading}
                label="Codigo OT"
                name="code"
                error={errors?.code?.message}
                errorMsg={errors?.code?.message ?? null}
                inputProps={{
                  ref: register({
                    required: {
                      value: true,
                      message: `El codigo de OT es requerido`,
                    },
                    validate: (value) =>
                      (value && checkTextLength(value, 1)) || `El codigo de la OT debe contener al menos 1 caracter`,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputContainer} style={{ paddingRight: `10px` }}>
              <TextFieldInput
                disabled={isLoading}
                label="Plazo (Días)"
                name="timeLimit"
                type="number"
                inputProps={{
                  ref: register({
                    required: {
                      value: true,
                      message: `El plazo es requerido`,
                    },
                  }),
                  min: 0,
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputContainer} style={{ paddingLeft: `10px` }}>
              <Controller
                control={control}
                name="subUnitId"
                rules={{
                  required: {
                    value: true,
                    message: `La subunidad es requerida`,
                  },
                  validate: (value) => {
                    return (value && checkSelectorValue(value)) || `Se debe seleccionar la subunidad`
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <SelectorInput
                    disabled={isLoading}
                    label="Subunidad"
                    selectorOptions={subUnits}
                    error={errors?.subUnitId?.message}
                    errorMsg={errors?.subUnitId?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <AddressInput
                disabled={isLoading}
                streetData={streetData}
                textAddress={textAddress}
                errorMsg={
                  checkTextLength(textAddress, 1)
                    ? null
                    : addressErrorCanBeShown
                    ? `Se debe seleccionar la dirección`
                    : null
                }
                handleSearch={handleSearch}
                debounceSearch={debounceSearch.callback}
                onSelect={onSelect}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <Controller
                control={control}
                name="orderTaskId"
                rules={{
                  required: {
                    value: true,
                    message: `El tipo es requerido`,
                  },
                  validate: (value) => {
                    return (value && checkSelectorValue(value)) || `Se debe seleccionar el tipo`
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <SelectorInput
                    disabled={isLoading}
                    label="Tipo"
                    selectorOptions={taskTypes}
                    error={errors?.orderTaskId?.message}
                    errorMsg={errors?.orderTaskId?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <Controller
                control={control}
                name="treeId"
                rules={{
                  required: {
                    value: true,
                    message: `La especie es requerida`,
                  },
                  validate: (value) => {
                    return (value && checkSelectorValue(value)) || `Se debe seleccionar la especie`
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <AutocompleteInput
                    disabled={isLoading || !trees.length}
                    label="Especie"
                    options={trees}
                    error={errors?.treeId?.message}
                    errorMsg={errors?.treeId?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid container className={classes.inputContainer}>
              <Grid item xs={6} style={{ paddingRight: `10px` }}>
                <Controller
                  control={control}
                  name="treeAge"
                  render={({ onChange, onBlur, value }) => (
                    <SelectorInput
                      disabled={isLoading}
                      label="Edad"
                      selectorOptions={treeAgesEnum}
                      error={errors?.treeAge?.message}
                      errorMsg={errors?.treeAge?.message ?? null}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: `10px` }}>
                <TextFieldInput
                  disabled={isLoading}
                  label="D.A.P."
                  name="treeDap"
                  error={errors?.treeDap?.message}
                  errorMsg={errors?.treeDap?.message ?? null}
                  inputProps={{
                    ref: register({
                      required: {
                        value: true,
                        message: `El D.A.P. es requerido`,
                      },
                      validate: (value) =>
                        (value && checkTextLength(value, 1)) || `El D.A.P. debe contener al menos 1 caracter`,
                    }),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.inputContainer} style={{ paddingRight: `10px` }}>
              <TextFieldInput
                disabled={isLoading}
                label="Altura"
                name="treeHeight"
                inputProps={{
                  ref: register(),
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputContainer} style={{ paddingLeft: `10px` }}>
              <TextFieldInput
                disabled={isLoading}
                label="Nº árboles"
                name="qtyTree"
                type="number"
                defaultValue={1}
                inputProps={{
                  ref: register(),
                  min: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <Controller
                control={control}
                name="generalState"
                rules={{
                  required: {
                    value: true,
                    message: `El estado general es requerido`,
                  },
                  validate: (value) => {
                    return (value && checkSelectorValue(value)) || `Se debe seleccionar el estado general`
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <SelectorInput
                    disabled={isLoading}
                    label="Estado general"
                    selectorOptions={generalStateEnum}
                    error={errors?.generalState?.message}
                    errorMsg={errors?.generalState?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <Controller
                control={control}
                name="phytosanitaryStateId"
                rules={{
                  required: {
                    value: true,
                    message: `El estado fitosanitario es requerido`,
                  },
                  validate: (value) =>
                    (value && checkSelectorValue(value)) || `Se debe seleccionar el estado fitosanitario`,
                }}
                render={({ onChange, onBlur, value }) => (
                  <SelectorInput
                    disabled={isLoading}
                    label="Estado fitosanitario"
                    selectorOptions={phytosanitaryStates}
                    error={errors?.phytosanitaryStateId?.message}
                    errorMsg={errors?.phytosanitaryStateId?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <Controller
                control={control}
                name="locationId"
                rules={{
                  required: {
                    value: true,
                    message: `La ubicacion es requerida`,
                  },
                  validate: (value) => {
                    return (value && checkSelectorValue(value)) || `Se debe seleccionar la ubicacion`
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <SelectorInput
                    disabled={isLoading}
                    label="Ubicacion"
                    selectorOptions={locations}
                    error={errors?.locationId?.message}
                    errorMsg={errors?.locationId?.message ?? null}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <TextFieldInput
                disabled={isLoading}
                label="Observaciones"
                name="observation"
                multiline={true}
                rows={6}
                inputProps={{
                  ref: register(),
                  maxLength: 160,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputContainer}>
              <UploadImage
                disabled={isLoading}
                openDialog={openDialog}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                onSelected={handleOnSelected}
                onDeleted={handleOnDeleted}
                files={files}
                webcamRef={webcamRef}
                capture={capture}
                acceptActionTextDialog="Tomar foto"
                handleScreenShot={handleScreenShot}
                screenShot={screenShot}
                maxFileSize={maxFileImg}
                onMaxFileSizeError={handleMaxSizeFile}
                accept={imgAccepted}
              />
            </Grid>
            <Grid item xs={12} align="center" className={classes.inputContainer}>
              <Button variant={formIsValid ? `contained` : `formOutlined`} type="submit" isLoading={isLoading}>
                Ingresar OT
              </Button>
            </Grid>
          </Grid>
          <DialogApproveTask
            openDialog={openConfirmationDialog}
            successDialog={false}
            handleApproveTask={onSubmit}
            handleCloseDialogApproveTask={handleCloseConfirmationDialog}
            taskDialog={taskDialog}
            isLoading={isLoading}
            acceptActionText="Ingresar"
          />
        </form>
      </Box>
    </>
  )
}

export default NewTask
