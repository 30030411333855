import React from 'react'
import ReactDOM from 'react-dom'
import { AuthProvider } from './context'
import App from './App'
import 'react-image-gallery/styles/css/image-gallery.css'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById(`root`),
)
