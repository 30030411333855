const rolesKindEnum = {
  admon: `Super Admin`,
  inspector: `Inspector Municipal Lo Barnechea`,
  contractor: `Supervisor Contratista`,
  boss: `Jefe de Cuadrilla`,
  admin: `Admin Contratista Lo Barnechea`,
}

const statusKindEnum = {
  Created: `Creada`,
  Approved: `Aprobada`,
  Done: `Realizada`,
  NotDone: `No Realizada`,
  Update: `Actualizar`,
  Declined: `Rechazada`,
  InProcess: `En proceso`,
}

const statusEnum = [
  { id: 1, description: `Creada` },
  { id: 2, description: `Realizada` },
  { id: 3, description: `No Realizada` },
  { id: 4, description: `Rechazada` },
  { id: 5, description: `Aprobada` },
  { id: 6, description: `En proceso` },
]

const unitsAllowEnum = {
  barnechea: 14,
}

const allowUpdatedByStatusIdInspectorEnum = [2, 3, 4]
const allowUpdatedByStatusIdContractor = [1, 3, 6]

const statusSelectInspectorEnum = [
  { id: 5, description: `Aprobar` },
  { id: 4, description: `Rechazar` },
]

const statusSelectContractorEnum = [
  { id: 6, description: `En proceso` },
  { id: 2, description: `Realizada` },
  { id: 3, description: `No Realizada` },
]

const treeAgesEnum = [
  { id: `Joven`, description: `Joven` },
  { id: `Intermedia`, description: `Intermedia` },
  { id: `Adulto`, description: `Adulto` },
  { id: `Senescente`, description: `Senescente` },
]

const generalStateEnum = [
  { id: `Bueno`, description: `Bueno` },
  { id: `Regular o medio`, description: `Regular o medio` },
  { id: `Malo o pobre`, description: `Malo o pobre` },
  { id: `Muerto`, description: `Muerto` },
]

const orderTaskPlantationEnum = 19

export {
  rolesKindEnum,
  statusEnum,
  statusKindEnum,
  unitsAllowEnum,
  allowUpdatedByStatusIdInspectorEnum,
  allowUpdatedByStatusIdContractor,
  statusSelectInspectorEnum,
  statusSelectContractorEnum,
  treeAgesEnum,
  generalStateEnum,
  orderTaskPlantationEnum,
}
