import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

import { TextFieldInput } from 'components'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    '&.MuiAutocomplete-root': {
      '& > .MuiFormControl-root': {
        '&.MuiTextField-root': {
          paddingBottom: `15px`,
          '& > .MuiInputBase-root': {
            '& > .MuiAutocomplete-endAdornment': {
              top: `calc(50% - 13px)`,
              right: `10px`,
            },
          },
        },
      },
    },
  },
  errorComponent: {
    paddingLeft: spacing(3),
  },
  subtitle: {
    marginLeft: spacing(1),
    color: palette.primary.contrastText,
  },
}))

function AutocompleteInput({ disabled, options, value, error, errorMsg, defaultValue, label = ``, name, onChange }) {
  const classes = useStyles()
  return (
    <Autocomplete
      id="autocomplete-input"
      classes={{ root: classes.root }}
      disabled={disabled}
      options={options}
      defaultValue={defaultValue}
      renderOption={(option) => (
        <React.Fragment>
          {option?.description.split(`,`)[0] ?? ``}
          {option?.description.split(`, `)[1] ? (
            <i className={classes.subtitle}>{option?.description.split(`, `)[1]}</i>
          ) : (
            ``
          )}
        </React.Fragment>
      )}
      getOptionLabel={(option) => option?.description}
      renderInput={(params) => (
        <TextFieldInput {...params} errorMsg={errorMsg} error={error} label={label} value={value} />
      )}
      onChange={(_event, selectedValue) => onChange(selectedValue)}
      name={name}
    />
  )
}

export default AutocompleteInput
