import { useState } from 'react'

export function useStateWithMerge(initialState) {
  const [state, setState] = useState(initialState)
  function mergeState(newState, shallowUpdate = true) {
    if (shallowUpdate) {
      return setState((prevState) => {
        const stateToMerge = typeof newState == `object` ? newState : newState(prevState)
        return { ...prevState, ...stateToMerge }
      })
    }
    return setState(newState)
  }
  return [state, mergeState]
}
