import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { statusKindEnum } from 'utils'
import ChipStatus from './ChipStatus'

const useStyles = makeStyles(({ spacing, palette, shadows }) => ({
  root: {
    borderRadius: spacing(2),
  },
  title: {
    fontWeight: 400,
  },
  chip: {
    width: `100%`,
  },
  subtitle: {
    color: palette.secondary.main,
  },
  contentShowAll: {
    width: `100%`,
    textDecoration: `underline`,
  },
  chipPrimary: {
    boxShadow: shadows[1],
  },
}))

function ListTasks({ tasks = [], showAll = false, goToShowAll = () => {}, getTask = () => {}, actionChip = () => {} }) {
  const classes = useStyles()
  return (
    <List component="nav" aria-label="task">
      {tasks.map(({ id, orderTask, streetName, streetNumber, changeStatuses }) => (
        <ListItem button disableGutters divider key={id} onClick={() => getTask(id)}>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={8}>
              <Typography variant="body1" noWrap className={classes.title}>
                {orderTask?.description}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                className={classes.subtitle}
              >{`${streetName} ${streetNumber}`}</Typography>
            </Grid>
            <Grid item xs={4}>
              <ChipStatus statuses={changeStatuses} taskId={id} actionChip={actionChip} statusKind={statusKindEnum} />
            </Grid>
          </Grid>
        </ListItem>
      ))}
      {!showAll && (
        <ListItem button disableGutters alignItems="center">
          <Typography align="center" variant="body2" className={classes.contentShowAll} onClick={goToShowAll}>
            Ver todas
          </Typography>
        </ListItem>
      )}
    </List>
  )
}

export default ListTasks
