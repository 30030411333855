import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Login } from './screens'

const routes = [
  {
    path: `/`,
    component: Login,
    exact: true,
  },
]

export default function PublicApp() {
  return (
    <>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} exact={exact} path={path} component={component} />
        ))}
        <Redirect to={routes[0].path} />
      </Switch>
    </>
  )
}
