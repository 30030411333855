import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(({ shadows, palette }) => ({
  chip: {
    width: `100%`,
  },
  chipInProcess: {
    width: `100%`,
    color: `#FFFFFF`,
    backgroundColor: palette.primary.main,
  },
  chipPrimary: {
    boxShadow: shadows[1],
  },
  chipDone: {
    width: `100%`,
    color: palette.functional.link,
    borderColor: palette.functional.link,
  },
  chipRejected: {
    width: `100%`,
    color: palette.functional.error,
    borderColor: palette.functional.error,
  },
  chipNotDone: {
    width: `100%`,
    color: palette.functional.warning,
    borderColor: palette.functional.warning,
  },
}))

function ChipStatus({ statuses = [], taskId, actionChip = () => {}, statusKind, ...props }) {
  const classes = useStyles()
  const statusLast = statuses[statuses.length - 1] ?? {}
  const {
    status: { description },
  } = statusLast

  if (description === statusKind.Approve || description === statusKind.Update) {
    return (
      <Chip
        className={clsx(classes.chip, classes.chipPrimary)}
        label={description}
        color="primary"
        clickable
        onClick={() => actionChip(taskId)}
      />
    )
  }
  if (description === statusKind.InProcess) {
    return <Chip className={classes.chipInProcess} label={description} variant="contained" color="primary" />
  }
  if (description === statusKind.Approved) {
    return <Chip className={classes.chip} label={description} variant="outlined" color="default" disabled />
  }
  if (description === statusKind.Created) {
    return <Chip className={classes.chip} label={description} {...props} variant="outlined" color="primary" />
  }
  if (description === statusKind.Done) {
    return <Chip className={classes.chipDone} label={description} {...props} variant="outlined" />
  }
  if (description === statusKind.Declined) {
    return <Chip className={classes.chipRejected} label={description} {...props} variant="outlined" />
  }
  if (description === statusKind.NotDone) {
    return <Chip className={classes.chipNotDone} label={description} {...props} variant="outlined" />
  }
  return <Chip className={classes.chip} label={description} variant="outlined" color="secondary" />
}

export default ChipStatus
