import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'

import { useStateWithMerge } from 'hooks'
import { useTask } from 'context'
import { Box, Paper, ListTasks } from 'components'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
  },
  gridContent: {
    height: `65vh`,
  },
  btnAction: {
    padding: spacing(3),
    backgroundColor: palette.primary.main,
    height: spacing(10),
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    cursor: `pointer`,
    flexDirection: `column`,
    borderRadius: spacing(4),
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  iconPlus: {
    fontSize: 48,
  },
  contentTask: {
    padding: spacing(0, 1),
  },
}))

function Home({ history }) {
  const classes = useStyles()
  const { tasks, getTask, resetView } = useTask()
  const [state, setState] = useStateWithMerge({
    tasksFilter: [],
  })

  const { tasksFilter } = state

  useEffect(() => {
    const data = []
    for (const task of tasks) {
      if (data.length < 3) data.push(task)
    }
    setState({ tasksFilter: data })
    resetView()
  }, [tasks])

  const handleNavigation = () => history.push(`/home/new-task`)
  const handleShowTasks = () => history.push(`/home/task`)

  const handleShowTask = async (taskId) => {
    await getTask(taskId)
    handleShowTasks()
  }

  return (
    <Box classes={{ root: classes.root }}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.gridContent}>
        <Grid item xs={11}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={8} md={6}>
              <Paper classes={{ root: classes.btnAction }} onClick={handleNavigation}>
                <AddIcon className={classes.iconPlus} />
                <Typography align="center" variant="body1">
                  Ingresar OT
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {tasksFilter.length > 0 ? (
            <>
              <Typography align="center" variant="body1" gutterBottom>
                Últimas actualizaciones OT
              </Typography>
              <Paper className={classes.contentTask}>
                <ListTasks tasks={tasksFilter} getTask={handleShowTask} goToShowAll={handleShowTasks} />
              </Paper>
            </>
          ) : (
            <Typography variant="h6" align="center">
              Usted no posee OT asignadas o no se encontraron OT creadas
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home
