/* eslint-disable no-use-before-define */
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

import { TextFieldInput } from 'components'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '&.MuiAutocomplete-root': {
      '& > .MuiFormControl-root': {
        '&.MuiTextField-root': {
          paddingBottom: `15px`,
          '& > .MuiInputBase-root': {
            '& > .MuiAutocomplete-endAdornment': {
              top: `calc(50% - 13px)`,
              right: `10px`,
            },
          },
        },
      },
    },
  },
  errorComponent: {
    paddingLeft: spacing(3),
  },
}))

export default function AddressInput({
  disabled,
  streetData,
  handleSearch,
  defaultValue,
  textAddress,
  errorMsg,
  onSelect,
}) {
  const classes = useStyles()
  return (
    <Autocomplete
      id="address-input"
      classes={{ root: classes.root }}
      disabled={disabled}
      options={streetData}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option?.description}
      renderInput={(params) => (
        <TextFieldInput {...params} errorMsg={errorMsg} label="Dirección" value={textAddress} onChange={handleSearch} />
      )}
      onChange={(_event, selectedValue) => onSelect(selectedValue)}
      name="address"
    />
  )
}
