import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Dialog from './Dialog'

const useStyles = makeStyles(({ spacing, palette }) => ({
  rootSuccess: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
  },
  textCodeOT: {
    color: palette.primary.main,
  },
  iconSuccess: {
    fontSize: 48,
    color: palette.primary.main,
    marginBottom: spacing(2),
  },
}))

function DialogApproveTask({
  openDialog = false,
  successDialog = false,
  handleApproveTask = () => {},
  handleCloseDialogApproveTask = () => {},
  taskDialog = {},
  isLoading = false,
  acceptActionText = `Aprobar`,
}) {
  const classes = useStyles()
  const { code, title } = taskDialog
  return (
    <Dialog
      open={openDialog}
      acceptAction={successDialog ? null : handleApproveTask}
      acceptActionText={acceptActionText}
      onClose={handleCloseDialogApproveTask}
      isLoading={isLoading}
    >
      {successDialog ? (
        <Box className={classes.rootSuccess}>
          <CheckCircleOutlineIcon className={classes.iconSuccess} />
          <Typography gutterBottom align="center">
            ¡El estado de la OT <b>{title}</b>
            {` `}
            <span className={classes.textCodeOT}>{`#${code}`}</span>, ha sido actualizado exitosamente!
          </Typography>
        </Box>
      ) : (
        <Typography align="center" variant="body1">
          ¿Esta seguro de {acceptActionText} la OT <b>{title}</b>
          {` `}
          <span className={classes.textCodeOT}>{`#${code}`}</span>?
        </Typography>
      )}
    </Dialog>
  )
}

export default DialogApproveTask
