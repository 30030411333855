import React, { useState, useRef, useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonMUI from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { Box, Button, TextFieldInput, DialogApproveTask, SelectorInput, UploadImage } from 'components'
import { statusSelectContractorEnum } from 'utils'
import { useFeedback } from 'context'

import API from 'config/api'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
    padding: spacing(3),
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
  },
  contentTitle: {
    marginTop: spacing(4),
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  titleDetail: {
    fontWeight: 600,
  },
  field: {
    color: palette.primary.main,
    textTransform: `uppercase`,
    fontWeight: 700,
    fontSize: 14,
  },
  rootSuccess: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
  },
  iconSuccess: {
    fontSize: 64,
    color: palette.primary.main,
  },
  btnBack: {
    color: palette.primary.main,
    textDecoration: `underline`,
    marginTop: spacing(3),
  },
}))

function EditTask({ task, toBack = () => {}, getAllTasks = () => {} }) {
  const classes = useStyles()
  const setFeedback = useFeedback()
  const webcamRef = useRef(null)
  const maxFileImg = 2097152
  const imgAccepted = `image/jpg, image/jpeg, image/png`
  const { code, id, orderTask } = task
  const { register, watch, errors, control } = useForm()
  const { observations, status } = watch()
  const [state, setState] = useState({
    success: false,
    isLoading: false,
    openDialog: false,
    taskDialog: {},
    openDialogImg: false,
    files: [],
    screenShot: false,
  })
  const { success, isLoading, openDialog, taskDialog, openDialogImg, files, screenShot } = state

  const handleAllowSubmit = () => {
    return observations && observations.length > 0 && status && status !== `select`
  }

  const handleSaveObservation = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true, openDialog: false }))
      if (files.length) {
        const { originalFile, name } = files[0]
        const formData = new FormData()
        formData.append(`file`, originalFile)
        formData.append(`name`, name)
        formData.append(`taskId`, id)
        formData.append(`observations`, observations)
        formData.append(`statusId`, status)
        await API.updateTaskRequestWithImg(
          { formData },
          {
            headers: { 'Content-Type': `multipart/form-data` },
          },
        )
      } else {
        await API.updateTaskRequest({ taskId: id, observations, statusId: status })
      }
      getAllTasks()
      setState({ success: true })
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false, openDialog: false }))
      setFeedback({
        message: error,
        type: `error`,
        open: true,
      })
    }
  }

  const handleUpdateTask = () => {
    if (handleAllowSubmit()) {
      const task = { id, code, title: orderTask?.description }
      setState((prevState) => ({ ...prevState, openDialog: true, taskDialog: task }))
    }
  }

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, openDialog: false }))
  }

  const handleOpenDialogImg = () => {
    if (!isLoading) {
      setState((prevState) => ({ ...prevState, openDialogImg: true }))
    }
  }

  const handleCloseDialogImg = () => {
    setState((prevState) => ({ ...prevState, openDialogImg: false, screenShot: false }))
  }

  const handleOnSelected = ({ file, name, originalFile }) => {
    const currentDate = Math.round(new Date().getTime() / 1000)

    setState((prevState) => {
      const { files } = prevState
      const nextFiles = files.length ? [...files] : []
      nextFiles.push({ file, name: `${currentDate}_${name}`, originalFile })
      return { files: nextFiles }
    })
  }

  const handleOnDeleted = (index) => {
    const nextFiles = files.filter((file, key) => index !== key)
    setState((prevState) => ({ ...prevState, files: nextFiles }))
  }

  const capture = useCallback(() => {
    const file = webcamRef.current.getScreenshot()
    fetch(file)
      .then((res) => res.blob())
      .then((blob) => {
        handleOnSelected({ file, name: `screenshot.jpeg`, originalFile: blob })
      })
    handleCloseDialog()
  }, [webcamRef])

  const handleScreenShot = () => {
    setState((prevState) => ({ ...prevState, screenShot: true }))
  }

  const handleMaxSizeFile = (error) => {
    if (error) {
      setFeedback({
        message: `El archivo no puede pesar más de ${maxFileImg / 1024 / 1024} MB`,
        type: `error`,
        open: true,
      })
    }
  }

  return (
    <Box className={classes.root}>
      {success ? (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.rootSuccess}>
          <Grid item xs={12} align="center">
            <CheckCircleOutlineIcon className={classes.iconSuccess} />
            <Typography variant="h5" gutterBottom>
              ¡Orden actualizada exitosamente!
            </Typography>
            <Typography variant="body1">Ahora un inspector debe revisarla y aprobarla.</Typography>
            <ButtonMUI className={classes.btnBack} onClick={toBack}>
              Volver al listado
            </ButtonMUI>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography className={classes.titleDetail}>Detalle de la OT</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="body1">{`#${code}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentTitle}>
            <Typography className={classes.field}>Tarea</Typography>
            <Typography className={classes.title}>{orderTask?.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              defaultValue="select"
              rules={{
                required: {
                  value: true,
                  message: `El estado de la OT es requerido`,
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <SelectorInput
                  disabled={isLoading}
                  label="Estado OT"
                  selectorOptions={statusSelectContractorEnum}
                  error={errors?.status?.message}
                  errorMsg={errors?.status?.message ?? null}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldInput
              label="Observaciones"
              name="observations"
              multiline={true}
              disabled={isLoading}
              error={errors?.observations?.message}
              errorMsg={errors?.observations?.message ?? null}
              rows={6}
              inputProps={{
                ref: register({
                  required: {
                    value: true,
                    message: `Este campo es requerido`,
                  },
                }),
                maxLength: 160,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadImage
              disabled={isLoading}
              openDialog={openDialogImg}
              handleOpenDialog={handleOpenDialogImg}
              handleCloseDialog={handleCloseDialogImg}
              onSelected={handleOnSelected}
              onDeleted={handleOnDeleted}
              files={files}
              webcamRef={webcamRef}
              capture={capture}
              acceptActionTextDialog="Tomar foto"
              handleScreenShot={handleScreenShot}
              screenShot={screenShot}
              maxFileSize={maxFileImg}
              onMaxFileSizeError={handleMaxSizeFile}
              accept={imgAccepted}
              maxFilesAttach={1}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant={handleAllowSubmit() ? `contained` : `formOutlined`}
              onClick={handleAllowSubmit() ? handleUpdateTask : () => {}}
              isLoading={isLoading}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      )}
      <DialogApproveTask
        openDialog={openDialog}
        handleApproveTask={handleSaveObservation}
        handleCloseDialogApproveTask={handleCloseDialog}
        taskDialog={taskDialog}
        isLoading={isLoading}
        acceptActionText="Actualizar"
      />
    </Box>
  )
}

export default EditTask
