import { createMuiTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale'

// import lightGreen from '@material-ui/core/colors/lightGreen'
// import grey from '@material-ui/core/colors/grey'

const locale = `esES`

export const colors = {
  primary: {
    main: `#A3CB38`,
    contrastText: `#424242`,
  },
  secondary: {
    main: `#C1C7D0`,
    contrastText: `#FFF`,
  },
  ternary: {
    main: `#FAFBFC`,
    dark: `#EBEBEB`,
    contrastText: `#494949`,
  },
  functional: {
    link: `#1890FF`,
    warning: `#FAAD14`,
    error: `#FF4D4F`,
  },
}

const theme = createMuiTheme(
  {
    palette: {
      primary: colors.primary,
      secondary: colors.secondary,
      ternary: colors.ternary,
      functional: colors.functional,
    },
  },
  locales[locale],
)

export default theme
