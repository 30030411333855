import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useAuth } from 'context'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    width: `100%`,
    backgroundColor: palette.primary.main,
    borderTopRightRadius: spacing(4),
    borderTopLeftRadius: spacing(4),
    padding: spacing(1, 0),
  },
  navigationRoot: {
    '& > .Mui-selected': {
      color: `#FFF`,
    },
  },
}))

function Footer({ location, history, prefix, routes }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { logOut } = useAuth()

  const handleNavigation = (path) => {
    history.push(path.length > 1 ? `${prefix}${path}` : prefix)
  }

  useEffect(() => {
    const routeActive = location.pathname.substring(prefix.length).length
      ? location.pathname.substring(prefix.length)
      : `/`
    const showRoutes = routes.filter(({ showInFooter }) => showInFooter)
    const index = showRoutes.findIndex((route) => route.path === routeActive)
    if (index >= 0) setValue(index)
  }, [location])

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      classes={{ root: classes.navigationRoot }}
      className={classes.root}
    >
      {routes
        .filter(({ showInFooter }) => showInFooter)
        .map(({ path, title, icon }, key) => {
          return <BottomNavigationAction label={title} icon={icon} key={key} onClick={() => handleNavigation(path)} />
        })}
      <BottomNavigationAction label="Cerrar sesión" icon={<ExitToAppIcon />} onClick={logOut} />
    </BottomNavigation>
  )
}

export default withRouter(Footer)
