import React, { useContext } from 'react'
import { ThemeProvider as ThemeStyled } from '@material-ui/core/styles'
import theme from '../config/theme'

const ThemeContext = React.createContext()

function ThemeProvider({ children }) {
  return (
    <ThemeContext.Provider value={{ theme }}>
      <ThemeStyled theme={theme}>{children}</ThemeStyled>
    </ThemeContext.Provider>
  )
}

function useTheme() {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error(`useTheme must be used within a ThemeProvider`)
  }
  return context
}

export { ThemeProvider, useTheme }
