import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import Header from './Header'
import Footer from './Footer'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    minHeight: `100vh`,
    maxHeight: `100vh`,
    backgroundColor: palette.ternary.dark,
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    justifyContent: `space-between`,
  },
  boxContainer: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    marginTop: spacing(-3),
    zIndex: 12,
    maxHeight: `max-content`,
    overflow: `auto`,
    position: `relative`,
  },
}))

export default function PrivateLayout({ prefix, routes, children }) {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" disableGutters className={classes.root}>
      <Header />
      <Box className={classes.boxContainer}>{children}</Box>
      <Footer routes={routes} prefix={prefix} />
    </Container>
  )
}
