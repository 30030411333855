import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useTask } from 'context'

import logo from 'assets/logo_header.svg'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    paddingBottom: spacing(4),
    zIndex: 10,
  },
  imgContainer: {
    flexGrow: 1,
    textAlign: `center`,
    '& > img': {
      width: 100,
    },
  },
  iconBack: {
    color: `#FFF`,
  },
}))

export default function MenuAppBar() {
  const classes = useStyles()
  const { enableGoBack, resetView } = useTask()

  const handleGoBack = () => {
    resetView()
  }

  return (
    <AppBar position="static" elevation={0} className={classes.root}>
      <Toolbar>
        {enableGoBack && (
          <IconButton edge="start" className={classes.iconBack} aria-label="back" onClick={handleGoBack}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Box className={classes.imgContainer}>
          <img src={logo} alt="Logo" />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
