const handleError = (error) => {
  if (Array.isArray(error)) {
    let nextErrors = ``
    error.forEach((e) => {
      nextErrors += `${e} \n`
    })
    return nextErrors
  } else if (typeof error === `string` && error.length > 0) {
    return error
  }
  return `Ha ocurrido un problema inesperado, por favor intentar más tarde.`
}

export { handleError }
