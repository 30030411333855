import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonMUI from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { Box, Button, TextFieldInput, DialogApproveTask, SelectorInput } from 'components'
import { statusSelectInspectorEnum } from 'utils'
import { useFeedback } from 'context'

import API from 'config/api'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
    padding: spacing(3),
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
  },
  contentTitle: {
    marginTop: spacing(4),
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  titleDetail: {
    fontWeight: 600,
  },
  field: {
    color: palette.primary.main,
    textTransform: `uppercase`,
    fontWeight: 700,
    fontSize: 14,
  },
  rootSuccess: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
  },
  iconSuccess: {
    fontSize: 64,
    color: palette.primary.main,
  },
  btnBack: {
    color: palette.primary.main,
    textDecoration: `underline`,
    marginTop: spacing(3),
  },
}))

function EditTask({ task, toBack = () => {}, getAllTasks = () => {} }) {
  const classes = useStyles()
  const setFeedback = useFeedback()
  const { code, orderTask, id } = task
  const { register, watch, errors, control } = useForm()
  const { observations, status } = watch()
  const [state, setState] = useState({ success: false, isLoading: false, openDialog: false, taskDialog: {} })
  const { success, isLoading, openDialog, taskDialog } = state

  const handleAllowSubmit = () => {
    return observations && observations.length > 0 && status && status !== `select`
  }

  const handleSaveObservation = async () => {
    try {
      setState({ isLoading: true, openDialog: false })
      await API.updateTaskRequest({ taskId: id, observations, statusId: status })
      getAllTasks()
      setState({ success: true })
    } catch (error) {
      setState({ isLoading: false, openDialog: false })
      setFeedback({
        message: error,
        type: `error`,
        open: true,
      })
    }
  }

  const handleUpdateTask = () => {
    if (handleAllowSubmit) {
      const task = { id, code, title: orderTask?.description }
      setState({ openDialog: true, taskDialog: task })
    }
  }

  const handleCloseDialog = () => {
    setState({ openDialog: false })
  }

  return (
    <Box className={classes.root}>
      {success ? (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.rootSuccess}>
          <Grid item xs={12} align="center">
            <CheckCircleOutlineIcon className={classes.iconSuccess} />
            <Typography variant="h5" gutterBottom>
              ¡Orden actualizada exitosamente!
            </Typography>
            <ButtonMUI className={classes.btnBack} onClick={toBack}>
              Volver al listado
            </ButtonMUI>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography className={classes.titleDetail}>Detalle de la OT</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="body1">{`#${code}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentTitle}>
            <Typography className={classes.field}>Tarea</Typography>
            <Typography className={classes.title}>{orderTask?.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              defaultValue="select"
              rules={{
                required: {
                  value: true,
                  message: `El estado de la OT es requerido`,
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <SelectorInput
                  disabled={isLoading}
                  label="Estado OT"
                  selectorOptions={statusSelectInspectorEnum}
                  error={errors?.status?.message}
                  errorMsg={errors?.status?.message ?? null}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldInput
              label="Observaciones"
              name="observations"
              multiline={true}
              disabled={isLoading}
              error={errors?.observations?.message}
              errorMsg={errors?.observations?.message ?? null}
              rows={6}
              inputProps={{
                ref: register({
                  required: {
                    value: true,
                    message: `Este campo es requerido`,
                  },
                }),
                maxLength: 160,
              }}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant={handleAllowSubmit() ? `contained` : `formOutlined`}
              onClick={handleUpdateTask}
              isLoading={isLoading}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      )}
      <DialogApproveTask
        openDialog={openDialog}
        handleApproveTask={handleSaveObservation}
        handleCloseDialogApproveTask={handleCloseDialog}
        taskDialog={taskDialog}
        isLoading={isLoading}
        acceptActionText="Actualizar"
      />
    </Box>
  )
}

export default EditTask
