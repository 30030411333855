import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import moment from 'moment'
import 'moment/locale/es'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import BoxMUI from '@material-ui/core/Box'
import ImageIcon from '@material-ui/icons/Image'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import { BoxHeader, Box, ChipStatus, Dialog } from 'components'
import { statusKindEnum, allowUpdatedByStatusIdInspectorEnum } from 'utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.ternary.dark,
    padding: spacing(2, 3),
  },
  rootSecondary: {
    padding: 0,
    backgroundColor: `#FFF`,
    overflow: `auto`,
    overflowX: `hidden`,
  },
  title: {
    fontWeight: 700,
    fontSize: 24,
  },
  firstField: {
    color: palette.primary.main,
    textTransform: `uppercase`,
    fontWeight: 700,
    fontSize: 12,
  },
  field: {
    color: palette.primary.main,
    textTransform: `uppercase`,
    fontWeight: 700,
    fontSize: 12,
    borderTop: `1px solid ${palette.primary.contrastText}`,
    paddingTop: spacing(2),
  },
  lastField: {
    color: palette.primary.main,
    textTransform: `uppercase`,
    fontWeight: 700,
    fontSize: 12,
    paddingTop: spacing(2),
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    paddingBottom: spacing(1),
  },
  statusText: {
    fontWeight: 400,
    fontSize: 14,
  },
  userText: {
    fontWeight: 400,
    fontSize: 12,
  },
  observation: {
    fontWeight: 600,
    fontSize: 14,
  },
  detailsContainer: {
    padding: spacing(3),
  },
  imgCarrousel: {
    height: `250px`,
    '& div': {
      height: `100%`,
      width: `100vw`,
      '& .image-gallery-image': {
        height: `100% !important`,
        width: `100% !important`,
        objectFit: `fill !important`,
      },
    },
  },
  dateFieldMargin: {
    marginTop: `2px`,
  },
  ultimateGrid: {
    paddingBottom: spacing(3),
    borderTop: `1px solid ${palette.primary.contrastText}`,
  },
  statusContainer: {
    marginBottom: spacing(2.5),
  },
  chip: {
    width: `100%`,
  },
  colorWhite: {
    color: `#FFF`,
  },
  showImgBtn: {
    display: `flex`,
    alignItems: `center`,
  },
}))

function Show({ task, updatedTask = () => {}, onEditTask = () => {} }) {
  const classes = useStyles()
  const emptyFieldString = `--`
  const {
    id,
    images,
    orderTask,
    code,
    createdAt,
    timeLimit,
    subUnit,
    address,
    treeHeight,
    treeAge,
    treeDap,
    tree,
    generalState,
    qtyTree,
    phytosanitaryState,
    location,
    changeStatuses,
    assignedBy,
    streetName,
    streetNumber,
  } = task
  const [state, setState] = useState({ allowUpdated: false, openDialogShowImg: false, img: null, openMenu: null })
  const { allowUpdated, openDialogShowImg, img, openMenu } = state

  useEffect(() => {
    moment.locale(`es`)
  }, [])

  useEffect(() => {
    const statusLast = changeStatuses[changeStatuses.length - 1] ?? {}
    setState((prevState) => ({
      ...prevState,
      allowUpdated: allowUpdatedByStatusIdInspectorEnum.includes(statusLast.statusId),
    }))
  }, [changeStatuses])

  const renderFieldText = (text) => text ?? emptyFieldString

  const handleUpdateTask = () => {
    updatedTask(id)
  }

  const handleOpenDialogShowImg = (img) => {
    setState((prevState) => ({ ...prevState, openDialogShowImg: true, img }))
  }

  const handleCloseDialogShowImg = () => {
    setState((prevState) => ({ ...prevState, openDialogShowImg: false, img: null }))
  }

  const handleDaysAllow = () => {
    const remainingDays = timeLimit + moment(createdAt).diff(moment(), `days`)
    let data = { background: `#FF0000`, color: `#FFFFFF` }
    if (remainingDays >= 4 && remainingDays <= 6) {
      data = { background: `#EFFE00`, color: `#000000` }
    }
    if (remainingDays >= 7) {
      data = { background: `#4DFF00`, color: `#000000` }
    }

    const { background, color } = data
    return (
      <Chip
        size="small"
        label={`${remainingDays} Día(s)`}
        style={{
          backgroundColor: background,
          color,
        }}
      />
    )
  }

  const handleCloseMenu = () => {
    setState((prevState) => ({ ...prevState, openMenu: null }))
  }

  return (
    <>
      <BoxHeader className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Detalle de OT</Typography>
          </Grid>
          <Grid item>
            <BoxMUI display="flex" alignItems="center">
              <Typography variant="body2" noWrap>{`${streetName} ${streetNumber}`}</Typography>
              <IconButton
                onClick={(event) => setState((prevState) => ({ ...prevState, openMenu: event.currentTarget }))}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu id="simple-menu" keepMounted anchorEl={openMenu} open={Boolean(openMenu)} onClose={handleCloseMenu}>
                <MenuItem onClick={onEditTask}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  Editar
                </MenuItem>
              </Menu>
            </BoxMUI>
          </Grid>
        </Grid>
      </BoxHeader>
      <Box className={classes.rootSecondary}>
        {images.length > 0 && (
          <Grid container>
            <ImageGallery
              items={images.map(({ img }) => ({ original: img, originalClass: classes.imgCarrousel }))}
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              showPlayButton={false}
              showThumbnails={false}
              showNav={true}
            />
          </Grid>
        )}
        <Grid container direction="row" justify="center" spacing={1} className={classes.detailsContainer}>
          <Grid item xs={12}>
            <Typography className={classes.firstField}>Tarea</Typography>
            <Typography className={classes.title}>{renderFieldText(orderTask?.description)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Código</Typography>
            <Typography className={classes.text}>{`#${code}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Plazo</Typography>
            <Typography className={classes.text}>{handleDaysAllow(timeLimit)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Fecha de creación</Typography>
            <Typography className={classes.text} noWrap>
              {moment(createdAt).format(`LL`)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Sub Unidad</Typography>
            <Typography className={classes.text}>{renderFieldText(subUnit?.description)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.field}>Dirección</Typography>
            <Typography className={classes.text}>{renderFieldText(address)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Edad</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(treeAge)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>D.A.P</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(treeDap)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Altura</Typography>
            <Typography className={classes.text}>{renderFieldText(treeHeight)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.field}>Nº árboles</Typography>
            <Typography className={classes.text}>{renderFieldText(qtyTree)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.field}>Estado General</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(generalState)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.field}>Estado Fitosanitario</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(phytosanitaryState?.description)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.field}>Ubicación</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(location?.description)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.field}>Especie</Typography>
            <Typography className={classes.text} noWrap>
              {renderFieldText(tree?.description)} <i>({renderFieldText(tree?.specie)})</i>
            </Typography>
          </Grid>
          {assignedBy && (
            <Grid item xs={12}>
              <Typography className={classes.field}>Asignado a</Typography>
              <Typography className={classes.text} noWrap>
                {`${assignedBy.firstName} ${assignedBy.lastName}`}
              </Typography>
            </Grid>
          )}
          {changeStatuses && (
            <Grid item xs={12} className={classes.ultimateGrid}>
              <Grid container>
                <Typography className={classes.lastField}>Estado OT</Typography>
              </Grid>
              {changeStatuses.map(
                ({ statusId, observation, createdAt, status, img, user: { firstName, lastName } }, index) => (
                  <Grid
                    key={index}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.statusContainer}
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      <Typography className={classes.statusText}>{moment(createdAt).format(`lll`)}</Typography>
                      <Typography className={classes.userText}>
                        <b>Usuario:</b>
                        {` ${firstName} ${lastName}`}
                      </Typography>
                      {observation && (
                        <Typography className={classes.observation}>{renderFieldText(observation)}</Typography>
                      )}
                      {img && (
                        <Typography
                          color="primary"
                          variant="body2"
                          className={classes.showImgBtn}
                          onClick={() => handleOpenDialogShowImg(img)}
                        >
                          <ImageIcon /> Ver imagen adjunta
                        </Typography>
                      )}
                    </Grid>
                    <Grid container item xs={4} justify="flex-end">
                      <ChipStatus
                        statuses={[
                          {
                            statusId,
                            status,
                          },
                        ]}
                        taskId={id}
                        statusKind={statusKindEnum}
                      />
                    </Grid>
                  </Grid>
                ),
              )}
            </Grid>
          )}
          {allowUpdated && (
            <Grid item xs={6}>
              <Chip label="Actualizar" className={classes.chip} color="primary" clickable onClick={handleUpdateTask} />
            </Grid>
          )}
          <Dialog open={openDialogShowImg} onClose={handleCloseDialogShowImg}>
            <img src={img} alt="img-attach-ot-change-status" width="100%" />
          </Dialog>
        </Grid>
      </Box>
    </>
  )
}

export default Show
