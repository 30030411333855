import axios from 'axios'
import { TOKEN_KEY } from './constants'

function isDownloadUrl(url) {
  if (url.split(`/`).includes(`export`)) return true
  return false
}

const requestHandler = (config) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    config.headers.Authorization = token
  }

  config.headers.Authorization = token
  return config
}

const responseHandler = (response) => {
  const {
    data: dataBase,
    config: { url },
  } = response
  if (isDownloadUrl(url)) {
    return dataBase
  }
  const { result, error, success } = dataBase
  if (success) {
    return result
  }
  return Promise.reject(error)
}

const API = {}
const baseURL = process.env.REACT_APP_API_URL || `http://${window.location.hostname}:4000`

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true
  },
})

axiosInstance.interceptors.request.use(requestHandler, (error) => Promise.reject(error))

axiosInstance.interceptors.response.use(responseHandler, (error) => Promise.reject(error))

//AUTH
API.login = (email, password) => axiosInstance.post(`auth`, { email, password })

//TASK
API.createTaskRequest = (payload) => axiosInstance.post(`taskRequest`, payload)
API.getTaskById = ({ taskId }) => axiosInstance.get(`taskRequest/${taskId}/task`)
API.getAllTask = () => axiosInstance.get(`taskRequest/getAllTask/webApp`)
API.uploadFile = ({ formData }, extra) => axiosInstance.post(`taskRequest/image/upload`, formData, extra)
API.getAllTaskByTypeId = () => axiosInstance.get(`taskRequest/getAllTaskByTypeId`)
API.updateTaskRequest = (payload) => axiosInstance.put(`taskRequest`, payload)
API.approveTaskRequest = (payload) => axiosInstance.post(`taskRequest/approveTaskRequest`, payload)
API.updateTaskRequestWithImg = ({ formData }, extra) => axiosInstance.post(`taskRequest/updateWithImg`, formData, extra)
API.editTaskRequest = (payload) => axiosInstance.put(`taskRequest/updated`, payload)

//FIELDS
API.getPhytosanitaryStatesByUnitId = (payload) =>
  axiosInstance.post(`genericField/PhytosanitaryState/getAllByUnitId`, payload)
API.getLocationsByUnitId = (payload) => axiosInstance.post(`genericField/Location/getAllByUnitId`, payload)
API.getSubUnitsByUnitId = (unitId) => axiosInstance.get(`subUnit?unitId=${unitId}`)
API.getOrderTasks = () => axiosInstance.get(`orderTask/getOrderTasks`)
API.getTrees = () => axiosInstance.get(`orderTask/getLBTrees`)

export default API
