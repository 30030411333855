import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function SnackbarStyled({
  type = ``,
  message = `This is a error message!`,
  open = false,
  horizontal = `center`,
  vertical = `bottom`,
  setOpen = () => null,
  duration = 6000,
}) {
  const handleClose = (event, reason) => {
    if (reason === `clickaway`) {
      return
    }
    setOpen(false)
  }
  return open ? (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{ horizontal, vertical }}>
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  ) : null
}

export default SnackbarStyled
