import React, { useEffect } from 'react'
import { useTask } from 'context'
import { useStateWithMerge } from 'hooks'
import { DialogApproveTask } from 'components'
import ShowTask from './Show'
import AllTask from './All'
import EditTask from './Edit'
import UpdateTask from './Update'

import API from 'config/api'

function Task() {
  const {
    task,
    tasks,
    getTask,
    handleGetAllTaskByTypeId,
    handleGetAllTask,
    updateTaskView,
    resetView,
    updatedTask,
    showTaskView,
  } = useTask()
  const [state, setState] = useStateWithMerge({
    openDialog: false,
    taskDialog: {},
    successDialog: false,
    isLoading: false,
    updateTask: false,
  })

  const { openDialog, taskDialog, successDialog, isLoading, updateTask } = state

  useEffect(() => {
    if (!task) setState({ updateTask: false })
  }, [task])

  const handleCloseDialogApproveTask = () => {
    setState({ openDialog: false, successDialog: false, isLoading: false })
  }

  const handleApproveTask = async () => {
    const { taskId } = taskDialog
    setState({ isLoading: true })
    await API.approveTaskRequest({ taskId })
    await handleGetAllTask()
    if (task) await getTask(taskId)
    setState({ successDialog: true, isLoading: false })
  }

  const handleEditTask = () => {
    setState({ updateTask: true })
  }

  const handleCloseEditTask = () => {
    setState({ updateTask: false })
  }

  return (
    <>
      {showTaskView && !updateTask && <ShowTask task={task} updatedTask={updatedTask} onEditTask={handleEditTask} />}
      {updateTaskView && !updateTask && <EditTask task={task} toBack={resetView} getAllTasks={handleGetAllTask} />}
      {!showTaskView && !updateTaskView && !updateTask && (
        <AllTask
          getTask={getTask}
          allTasks={tasks}
          orderByTypeId={handleGetAllTaskByTypeId}
          orderByStatus={handleGetAllTask}
          handleOpenDialogApproveTask={updatedTask}
        />
      )}
      {updateTask && <UpdateTask task={task} onClose={handleCloseEditTask} />}
      <DialogApproveTask
        openDialog={openDialog}
        successDialog={successDialog}
        handleApproveTask={handleApproveTask}
        handleCloseDialogApproveTask={handleCloseDialogApproveTask}
        taskDialog={taskDialog}
        isLoading={isLoading}
      />
    </>
  )
}

export default Task
