import React from 'react'
import { useTask } from 'context'
import AllTask from './All'
import EditTask from './Edit'
import ShowTask from './Show'

function Task() {
  const {
    task,
    tasks,
    getTask,
    updateTaskView,
    updatedTask,
    showTaskView,
    handleGetAllTaskByTypeId,
    handleGetAllTask,
    resetView,
  } = useTask()

  return (
    <>
      {showTaskView ? (
        <ShowTask task={task} updatedTask={updatedTask} />
      ) : updateTaskView ? (
        <EditTask task={task} toBack={resetView} getAllTasks={handleGetAllTask} />
      ) : (
        <AllTask
          getTask={getTask}
          allTasks={tasks}
          updatedTask={updatedTask}
          orderByTypeId={handleGetAllTaskByTypeId}
          orderByStatus={handleGetAllTask}
        />
      )}
    </>
  )
}

export default Task
