import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(({ spacing, palette, shadows }) => ({
  rootContained: {
    padding: spacing(1.5, 5),
    borderRadius: spacing(8),
    textTransform: `none`,
    fontWeight: 600,
    backgroundColor: palette.primary.main,
    boxShadow: shadows[1],
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  rootOutlined: {
    padding: spacing(1.5, 5),
    borderRadius: spacing(8),
    backgroundColor: palette.primary.main,
    textTransform: `none`,
    color: `#FFF`,
    border: `2px solid #fff`,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: palette.primary.dark,
      border: `2px solid #fff`,
    },
  },
  rootFormOutlined: {
    padding: spacing(1.5, 5),
    borderRadius: spacing(8),
    backgroundColor: `transparent`,
    textTransform: `none`,
    color: `#C1C7D0`,
    border: `2px solid #C1C7D0`,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: `transparent`,
      border: `2px solid #C1C7D0`,
    },
  },
}))

function ButtonStyled({ isLoading = false, children, variant = `contained`, ...props }) {
  const classes = useStyles()

  if (variant === `outlined`) {
    return (
      <Button {...props} variant={variant} classes={{ root: classes.rootOutlined }}>
        {isLoading ? <CircularProgress color="secondary" /> : children}
      </Button>
    )
  }

  if (variant === `formOutlined`) {
    return (
      <Button {...props} variant="outlined" classes={{ root: classes.rootFormOutlined }}>
        {isLoading ? <CircularProgress color="secondary" /> : children}
      </Button>
    )
  }

  return (
    <Button {...props} variant={variant} classes={{ root: classes.rootContained }}>
      {isLoading ? <CircularProgress color="secondary" /> : children}
    </Button>
  )
}

export default ButtonStyled
