import React, { useContext, useState } from 'react'
import { Snackbar } from 'components'
import { handleError } from 'utils'

const FeedbackContext = React.createContext()
const initialState = { message: ``, open: false, type: null }

function FeedbackProvider({ children }) {
  const [feedback, setFeedback] = useState(initialState)
  const handleSetFeedback = ({ open = true, message = ``, type = `error` }) => {
    setFeedback({ message: handleError(message), open, type })
  }
  return (
    <FeedbackContext.Provider value={handleSetFeedback}>
      <>
        {children}
        <Snackbar {...feedback} setOpen={(open) => handleSetFeedback({ open })} />
      </>
    </FeedbackContext.Provider>
  )
}

function useFeedback() {
  const context = useContext(FeedbackContext)
  if (!context) {
    throw new Error(`useFeedback must be used within a FeedbackProvider`)
  }
  return context
}

export { FeedbackProvider, useFeedback }
