import React, { useEffect, useState } from 'react'
import Webcam from 'react-webcam'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import CloseIcon from '@material-ui/icons/Close'

import { Paper, Dialog, Button } from 'components'

const useStyles = makeStyles(({ spacing }) => ({
  btnAddImg: {
    padding: spacing(2),
    textAlign: `center`,
  },
  btnWithImg: {
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    '& > img': {
      width: `100%`,
      heigth: `100%`,
    },
    '& > button': {
      position: `absolute`,
      right: spacing(1),
    },
  },
  btnWithOutImg: {
    backgroundColor: `transparent`,
    boxSizing: `border-box`,
    padding: spacing(2),
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    border: `1px dashed #A5ADBB`,
    boxShadow: `none`,
  },
  contentButton: {
    minHeight: 115,
    display: `flex`,
    flexDirection: `column`,
    flex: `1 1 auto`,
    position: `relative`,
  },
}))

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: { exact: `environment` },
}

function UploadImageCard({
  disabled = false,
  files = [],
  handleOpenDialog = () => {},
  handleCloseDialog = () => {},
  acceptActionTextDialog = `Tomar foto`,
  openDialog = false,
  accept = `image/*`,
  id = `buttonUpload`,
  onSelected = () => {},
  onDeleted = () => {},
  maxFileSize = null,
  onMaxFileSizeError = () => {},
  maxFilesAttach = 3,
  screenShot = false,
  webcamRef = null,
  capture = () => {},
  handleScreenShot = () => {},
}) {
  const classes = useStyles()
  const [skeleton, setSkeleton] = useState([])

  useEffect(() => {
    const items = []
    const iteration = maxFilesAttach - files.length - 1
    for (let index = 0; index < iteration; index++) {
      items.push(index)
    }
    setSkeleton(items)
  }, [files])

  return (
    <>
      <Grid container direction="row" spacing={1}>
        {files.map(({ file }, key) => (
          <Grid item xs={4} className={classes.contentButton} key={key}>
            <Paper classes={{ root: classes.btnWithImg }} style={{ backgroundImage: `url(${file})` }}>
              {!disabled && (
                <IconButton size="small" onClick={() => onDeleted(key)}>
                  <CloseIcon />
                </IconButton>
              )}
            </Paper>
          </Grid>
        ))}
        {files.length < maxFilesAttach && (
          <Grid item xs={4}>
            <Paper classes={{ root: classes.btnAddImg }} onClick={handleOpenDialog}>
              <AddAPhotoIcon />
              <Typography align="center" variant="body1">
                Agregar imagen
              </Typography>
            </Paper>
          </Grid>
        )}
        {skeleton.map((_, key) => (
          <Grid item xs={4} className={classes.contentButton} key={key}>
            <Paper classes={{ root: classes.btnWithOutImg }}></Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={openDialog}
        title="Subir imagen"
        onClose={handleCloseDialog}
        fullScreen={screenShot}
        acceptAction={screenShot ? capture : null}
        acceptActionText={acceptActionTextDialog}
        withOutPadding={screenShot}
      >
        {screenShot ? (
          <Webcam
            videoConstraints={videoConstraints}
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
          />
        ) : (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <input
                accept={accept}
                id={id}
                type={`file`}
                style={{ display: `none` }}
                onClick={(e) => (e.target.value = null)}
                onChange={(event) => {
                  const reader = new FileReader()
                  const file = event.target.files[0]
                  if (maxFileSize && file.size > maxFileSize) {
                    onMaxFileSizeError(true)
                    return
                  }
                  onMaxFileSizeError(false)
                  let name = ``
                  reader.addEventListener(
                    `load`,
                    () => onSelected({ file: reader.result, name, originalFile: file }),
                    false,
                  )
                  name = file.name
                  if (file) {
                    reader.readAsDataURL(file)
                    handleCloseDialog()
                  }
                }}
              />
              <label htmlFor={id}>
                <Button fullWidth={true} startIcon={<CloudUploadIcon />} component="span">
                  Seleccionar imagen
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth={true} startIcon={<PhotoCameraIcon />} onClick={handleScreenShot}>
                Tomar foto
              </Button>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  )
}

export default UploadImageCard
