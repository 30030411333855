import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    borderTopLeftRadius: spacing(2),
    borderTopRightRadius: spacing(2),
  },
}))

export function BoxHeaderStyled({ children, className, ...props }) {
  const classes = useStyles()
  return (
    <Box {...props} className={clsx(classes.root, className)}>
      {children}
    </Box>
  )
}

export default BoxHeaderStyled
