import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import { InspectorHome, Tasks, ContractorTasks, NewTask } from './screens'
import PrivateLayout from './layout/PrivateLayout'

import { useAuth, TaskProvider } from './context'

import { rolesKindEnum } from './utils'

export default function PrivateApp() {
  const prefix = `/home`
  const { user } = useAuth()

  const routes = [
    {
      path: `/`,
      title: `Inicio`,
      component: InspectorHome,
      validate: ({ Role }) => [rolesKindEnum.inspector, rolesKindEnum.admon].includes(Role.description),
      showInFooter: true,
      icon: <HomeIcon />,
    },
    {
      path: `/task`,
      title: `Tareas`,
      component: Tasks,
      validate: ({ Role }) => [rolesKindEnum.inspector, rolesKindEnum.admon].includes(Role.description),
      showInFooter: true,
      icon: <FormatListBulletedIcon />,
    },
    {
      path: `/new-task`,
      title: `Creacion de Tareas`,
      component: NewTask,
      validate: ({ Role }) => [rolesKindEnum.inspector, rolesKindEnum.admon].includes(Role.description),
      showInFooter: false,
    },
    {
      path: `/`,
      title: `Tareas`,
      component: ContractorTasks,
      validate: ({ Role }) =>
        [rolesKindEnum.contractor, rolesKindEnum.boss, rolesKindEnum.admin].includes(Role.description),
      showInFooter: true,
      icon: <FormatListBulletedIcon />,
    },
  ]
  const filteredRoutes = user ? routes.filter((route) => route.validate(user)) : []

  return (
    <TaskProvider>
      <PrivateLayout routes={filteredRoutes} prefix={prefix}>
        <Switch>
          {filteredRoutes.map(({ path, component, subRoutes = [] }) =>
            subRoutes.length > 0 ? (
              subRoutes.map((route) => (
                <Route key={route.path} exact path={`${prefix}${route.path}`} component={route.component} />
              ))
            ) : (
              <Route key={path} exact path={`${prefix}${path}`} component={component} />
            ),
          )}
          <Redirect to={`/`} />
        </Switch>
      </PrivateLayout>
    </TaskProvider>
  )
}
