import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(({ spacing, shadows, palette }) => ({
  root: {
    borderRadius: spacing(8),
    boxShadow: shadows[1],
    backgroundColor: `#FFF`,
    '& > .MuiFormLabel-root': {
      paddingLeft: `32px`,
      paddingTop: spacing(1),
      color: palette.secondary.main,
      '&.MuiInputLabel-shrink': {
        paddingTop: spacing(1),
        paddingLeft: `32px`,
      },
    },
    '& > .MuiInputBase-root': {
      padding: spacing(0, 3),
      paddingTop: `5px`,
      paddingBottom: `13px`,
      '& > .MuiSelect-select': {
        backgroundColor: `transparent`,
      },
      '& > .MuiSelect-icon': {
        top: `calc(50% - 16px)`,
        right: `10px`,
      },
    },
  },
  errorComponent: {
    paddingLeft: spacing(3),
  },
}))

function SelectorInput({
  label = ``,
  selectorOptions = [],
  onChange = () => {},
  inputProps,
  errorMsg = null,
  ...props
}) {
  const classes = useStyles()

  return (
    <>
      <TextField
        {...props}
        onChange={({ target }) => onChange(target.value)}
        classes={{ root: classes.root }}
        fullWidth
        label={label}
        select
        InputProps={{ ...inputProps, disableUnderline: true }}
      >
        <MenuItem value="select" disabled>
          Seleccione...
        </MenuItem>
        {selectorOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.description}
          </MenuItem>
        ))}
      </TextField>
      {errorMsg && (
        <FormControl error>
          <FormHelperText id="component-error-text" className={classes.errorComponent}>
            {errorMsg}
          </FormHelperText>
        </FormControl>
      )}
    </>
  )
}

export default SelectorInput
