import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { PublicLayout } from 'layout'
import { TextFieldInput, Button } from 'components'

import { useAuth, useFeedback } from 'context'
import { useStateWithMerge } from 'hooks'
import { rolesKindEnum, unitsAllowEnum } from 'utils'
import API from 'config/api'
import logo from 'assets/logo.svg'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: palette.primary.main,
    minHeight: `100vh`,
    padding: spacing(3),
  },
  imgLogo: {
    width: `70%`,
    margin: `auto`,
  },
  title: {
    color: `#FFF`,
    paddingLeft: spacing(3),
    textTransform: `uppercase`,
  },
  linkResetPassword: {
    color: `#FFF`,
  },
}))

function Login({ history }) {
  const classes = useStyles()
  const setFeedback = useFeedback()
  const { logIn, user } = useAuth()
  const { register, handleSubmit, errors, watch } = useForm()
  const [state, setState] = useStateWithMerge({
    isLoading: false,
  })
  const { email, password } = watch()
  const { isLoading } = state

  useEffect(() => {
    if (user) {
      history.push(`home`)
    }
  }, [])

  const onSubmit = async (data) => {
    setState({ isLoading: true })
    const { email, password } = data
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        // eslint-disable-next-line no-sequences
        .reduce((res, key) => ((res[key] = obj[key]), res), {})
    try {
      const login = await API.login(email, password)
      const { token } = login
      const user = Object.filter(login, (data) => data !== token)
      if (
        ((user?.Role?.description === rolesKindEnum.inspector ||
          user?.Role?.description === rolesKindEnum.contractor ||
          user?.Role?.description === rolesKindEnum.admin ||
          user?.Role?.description === rolesKindEnum.boss) &&
          [unitsAllowEnum.barnechea].includes(user?.unitId)) ||
        user?.Role?.description === rolesKindEnum.admon
      ) {
        logIn({ user, token })
        return history.push(`home`)
      }
      setFeedback({
        message: `No tiene permisos para usar esta App`,
        type: `error`,
        open: true,
      })
    } catch (error) {
      setFeedback({
        message: error,
        type: `error`,
        open: true,
      })
    }
    return setState({ isLoading: false })
  }

  return (
    <PublicLayout>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={12} align="center">
          <img src={logo} alt="Logo-arbotag" className={classes.imgLogo} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  Ingrese sus datos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextFieldInput
                  label="Correo electrónico"
                  name="email"
                  error={errors?.email?.message}
                  errorMsg={errors?.email?.message ?? null}
                  inputProps={{
                    ref: register({
                      required: {
                        value: true,
                        message: `El correo electrónico es requerido`,
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: `Debe ingresar un correo electrónico válido`,
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldInput
                  label="Contraseña"
                  type="password"
                  name="password"
                  error={errors?.password?.message}
                  errorMsg={errors?.password?.message ?? null}
                  inputProps={{
                    ref: register({
                      required: {
                        value: true,
                        message: `La contraseña es requerida`,
                      },
                      minLength: {
                        value: 6,
                        message: `Debe contener al menos 6 caracteres`,
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <Button variant={email && password ? `contained` : `outlined`} type="submit" isLoading={isLoading}>
                  Iniciar sesión
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </PublicLayout>
  )
}

export default withRouter(Login)
